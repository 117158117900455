import * as React from "react"
import Layout from "../layouts/default"
import Seo from "../components/seo"
import * as style from "../sass/pages/contact.module.sass"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { PortableText } from '@portabletext/react'


const ContactPage = ({ data }) => {
  const page = data.allSanityContact.edges[0].node;
  return (
    <Layout>
      <Seo title="Contact" />
      <section className={style.mainContent}>
        <PortableText
          value={page._rawHeader}
          components={{
            marks: {
              underline: ({ children }) => <u>{children}</u>,
            },
          }
          }
        />
        <div className={style.body}>
          <p className={style.description}>Give us a ring, send us an email, or reach out with a follow. I would love to personally speak with you and learn more about your coffee business. Take the leap.</p>
          <div>
            <p className="subtitle2"><strong>DAVID GURR</strong></p>
            <p className="subtitle2"><em>PRINCIPAL</em></p>
          </div>
          <div>
            <p className="subtitle2">OFFICE: <a href="tel:+1-833-638-4877" className="subtitle2">+1 833.638.4877</a></p>
            <p className="subtitle2">MOBILE: <a href="tel:+1-954-632-6367" className="subtitle2">+1 954.632.6367</a></p>
          </div>
          <div className={style.links}>
            <p className="subtitle2"><a href="mailto:gurr@thecoffeestandard.com" className="subtitle2">GURR@THECOFFEESTANDARD.COM</a></p>
            <p className="subtitle2"><a href="https://www.instagram.com/thecoffeestandard/" target="_blank" rel="noopener noreferrer" className="subtitle2">@THECOFFEESTANDARD</a></p>
            <p className="subtitle2"><Link to="/" className="subtitle2">THECOFFEESTANDARD.COM</Link></p>
          </div>

          <p className="subtitle2">"The Sunshine State"<br/>West Palm Beach, Florida</p>
          {/* <a href="https://goo.gl/maps/HYdmk2rf4GqtLJu86" target="_blank" rel="noopener noreferrer" className="subtitle2">1016 CLARE AVE SUITE 5<br />WEST PALM BEACH, FLORIDA<br />33401</a> */}
        </div>

      </section>
      <section className={style.gallery}>
        <div className={style.imageWrapper}>
          <GatsbyImage className={style.image} image={page.imageLeft.asset.gatsbyImageData} alt={page.imageLeft.alt} />
          <div className={page.imageLeft.luminosity === true ? style.metadata + ' ' + style.light : style.metadata}>
            <p className="caption captionBold">{page.imageLeft.title}</p>
            <p className="caption">{page.imageLeft.subtitle}</p>
          </div>
        </div>
        <div className={style.imageWrapper}>
          <GatsbyImage className={style.image} image={page.imageRight.asset.gatsbyImageData} alt={page.imageRight.alt} />
          <div className={page.imageRight.luminosity === true ? style.metadata + ' ' + style.light : style.metadata}>
            <p className="caption captionBold">{page.imageRight.title}</p>
            <p className="caption">{page.imageRight.subtitle}</p>
          </div>
        </div>
        <div className={style.imageWrapper}>
          <GatsbyImage className={style.image} image={page.imageFull.asset.gatsbyImageData} alt={page.imageFull.alt} />
          <div className={page.imageFull.luminosity === true ? style.metadata + ' ' + style.light : style.metadata}>
            <p className="caption captionBold">{page.imageFull.title}</p>
            <p className="caption">{page.imageFull.subtitle}</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage


export const query = graphql`
{
  allSanityContact {
    edges {
      node {
        _rawHeader
        imageLeft {
          title
          subtitle
          alt
          luminosity
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
        imageRight {
          title
          subtitle
          alt
          luminosity
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
        imageFull {
          title
          subtitle
          alt
          luminosity
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
}
`